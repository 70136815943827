import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
 
const Meta = ({
	lang,
	title,
	description,
	ogTitle,
	twTitle,
	ogDescription,
	ogImage,
}) => {
	const { site } = useStaticQuery(
		graphql`
       query {
         site {
           siteMetadata {
             title
             description
           }
         }
       }
     `,
	);

	const metaDescription = description || site.siteMetadata.description;
	const facebookTitle = ogTitle || title;
	const twitterTitle = twTitle || facebookTitle;
	const facebookDescription = ogDescription || metaDescription; 
	const defaultTitle = site.siteMetadata.title;
 
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: facebookTitle,
				},
				{
					property: 'og:description',
					content: facebookDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: ogImage,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: '',
				},
				{
					name: 'twitter:image',
					content: ogImage,
				},
				{
					name: 'twitter:title',
					content: twitterTitle,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
			]}
		/>
	);
};

export default Meta;