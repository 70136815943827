import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import Meta from '../components/meta';
import shareImageSoMe from '../images/shareimage_bagfacaden.png';

const CookieDeclarationPage = ({ data }) => {
	const [pageData] = data.locales.edges;
	const parsedData = JSON.parse(pageData.node.data);
	const { t } = useTranslation();

	useEffect(() => {
		document.body.classList.remove('fixed');
		document.body.classList.remove('overflow-hidden');

		return () => {
			const cookiebotContainer = document.querySelector('#cookiebot-container');
			cookiebotContainer.remove();
		};
	}, []);

	return (
		<Layout allPages={parsedData.buildings.pages}>
			<Meta
				lang={pageData.node.language}
				title={t('cookieDeclaration.seoTitle')}
				description={t('cookieDeclaration.seoDescription')}
				ogImage={shareImageSoMe}
			/>
			<section className="mx-auto max-w-screen-3xl pt-124 pb-60">
				<h1 className="text-center text-30 lg:text-48">{t('cookieDeclaration.displayTitle')}</h1>
				{/* Cookie declaration content is generated from the gatsby-ssr.js file */}
			</section>
		</Layout>
	);
};

export default CookieDeclarationPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;